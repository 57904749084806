import type { JSX } from 'react'
import type { ImageLoader } from 'next/image'

import type { CmsComponentsForAnalytics } from '@cms/analytics/types'
import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import useNormalizeReference from '@cms/hooks/useNormalizeReference'
import { FALLBACK_IMAGES, SMART_CROP_TOKENS } from '@cms/utils/constants'
import {
  getDefaultImageLoader,
  getDevicePixelRatioPriority,
  getImageWithFallback,
  getRenditionImageLoader,
  getSmartCropImageLoaderByTokenName,
} from '@cms/utils/utils'
import { formattedVideo } from '@cms/utils/video'
import { ScreenSM } from '@knauf-group/ct-designs/utils/hooks/mediaQuery'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import type { AnalyticsTargetType } from '@knauf-group/ct-shared-nextjs/web/analytics/types'
import { useOneTrust } from '@knauf-group/ct-shared-nextjs/web/hooks/useOneTrust'
import { useTheme } from '@mui/material/styles'

import { ChangeCookieSettingsCTA } from '../../cms/components/ChangeCookieSettingsCTA'
import { useStyles } from './Teaser.styles'
import type { TeaserProps, TeaserWrapperProps } from './Teaser.types'

const getImageSizes = (isProductImage: boolean) => {
  return isProductImage ? '(max-width: 899px) 100vw, 50vw' : '100vw'
}

const setAnalyticsProps = (
  target: AnalyticsTargetType,
  component: CmsComponentsForAnalytics,
) => {
  return {
    targetType: target,
    cmsComponent: component,
  }
}

const useFormattedVideoProps = (videoReference, cookieBanner: JSX.Element) => {
  const oneTrust = useOneTrust()
  return {
    videoContent: videoReference,
    index: 1,
    isTargetingCookieSet: oneTrust.isTargetingCookieSet,
    cookieSettingsTile: cookieBanner,
  }
}

const getImageLoader = (
  isProductImage: boolean,
  isSmartCropImage: boolean,
  devicePixelRatio: number,
): ImageLoader => {
  const devicePixelRatioPriority = getDevicePixelRatioPriority(devicePixelRatio)

  if (isProductImage) {
    return getRenditionImageLoader(devicePixelRatioPriority)
  }
  if (isSmartCropImage) {
    return getSmartCropImageLoaderByTokenName(
      SMART_CROP_TOKENS.CF_4X3_L,
      devicePixelRatioPriority,
    )
  }
  return getDefaultImageLoader()
}

export const useTeaserProps = (fields: TeaserWrapperProps['fields']): TeaserProps => {
  const { normalizeReference } = useNormalizeReference()
  const analyticsProps = setAnalyticsProps('link', 'content_teaser')

  const { image, primaryReference: primaryLink, videoReference, imageAlignment } = fields || {}
  const primaryRef = normalizeReference({ reference: primaryLink, analyticsProps })

  const { devicePixelRatio } = useDevicePixelRatio()

  const isProductImage = Boolean(fields?.productImage)
  const isSmartCropImage = !isProductImage && image?.[0]?.url
  // empty loader triggers the default one which applies the Optimization API which serves them directly from the app. See https://nextjs.org/docs/pages/building-your-application/optimizing/images#loaders
  const imageLoader = getImageLoader(isProductImage, isSmartCropImage, devicePixelRatio)
  const cookieBanner = <ChangeCookieSettingsCTA />
  const formattedVideoProps = useFormattedVideoProps(videoReference, cookieBanner)
  const video = formattedVideo(formattedVideoProps)
  // since we are returning fallback images on getImageWithFallback, we need to ensure it does not overlap with video thumbnail
  const imageWithFallback =
    !video && getImageWithFallback(image?.[0], FALLBACK_IMAGES.DEFAULT_4_3_RATIO)

  const theme = useTheme()
  const styles: Styles = useStyles(theme, imageAlignment, isProductImage)
  const isScreenSm = ScreenSM(theme)

  const imageSize = getImageSizes(isProductImage)

  return {
    ...fields,
    image: imageWithFallback,
    imageLoader,
    cookieSettingsTile: cookieBanner,
    primaryRef,
    video,
    isScreenSm,
    styles,
    imageSize,
  }
}

export default useTeaserProps
